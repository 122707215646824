<script>
export default {
  name: 'ContratosMainView',

  data () {
    return {
      loading: false,
      empresas: []
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      this.loading = true
      const response = await this.$store.dispatch('empresaModule/getSelectTree')
      this.empresas = response.data
      this.loading = false
    }
  }
}
</script>

<template>
  <base-view title="Contratos" icon="file">
    <div>
      <check-authorization :requiresAuthorizations="['indice contratos']">
        <b-card no-body>
          <b-card-header>
            Seleccionar empresa {{ $route.params }}
          </b-card-header>
          <b-card-body>
            <loading v-if="loading"></loading>
            <div v-else>
              <b-card no-body v-for="empresa in empresas" v-bind:key="empresa.id" class="mb-5">
                <b-card-header>
                  {{ empresa.rfc }} - {{ empresa.razon_social }}
                </b-card-header>
                <b-card-body>
                  <div class="mb-3">Proyectos:</div>
                  <ul v-if="empresa.condominios.length > 0">
                    <li v-for="proyecto in empresa.condominios" v-bind:key="proyecto.id">
                      <b-link :to="{ name: 'contratos-proyecto', params: { proyecto: proyecto.id }}">
                        {{ proyecto.nombre }}
                      </b-link>
                    </li>
                  </ul>
                  <ul v-else>
                    <li>
                      <span class="text-danger">No hay proyectos registrados para esta empresa</span>
                    </li>
                  </ul>
                </b-card-body>
              </b-card>
            </div>
          </b-card-body>
        </b-card>
      </check-authorization>
    </div>
  </base-view>
</template>

<style scoped>

</style>
